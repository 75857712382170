import {
  createFileRoute,
  Link,
  MatchRoute,
  Outlet,
  useParams,
} from "@tanstack/react-router";
import {
  ResizablePanel,
  ResizablePanelGroup,
} from "@/features/ui/resizable.tsx";
import { assistantQueries } from "@/services/queries/assistant.ts";
import { Nav, NavLink } from "@/features/layout/nav.tsx";
import {
  DashboardProvider,
  useDashboardContext,
} from "@/features/dashboard/dashboard.provider.tsx";
import { useSuspenseQuery } from "@tanstack/react-query";
import {
  BarChartIcon,
  BlocksIcon,
  BotIcon,
  CoinsIcon,
  MessageCircleIcon,
  SettingsIcon,
} from "lucide-react";
import { useAuth } from "@/features/layout/auth/auth.context.ts";
import { cn } from "@/lib/utils.ts";
import React from "react";

const Sidebar = React.lazy(() => import("@/features/layout/sidebar.tsx"));

export const Route = createFileRoute("/_authenticated/_dashboard")({
  component: () => {
    const { data } = useSuspenseQuery(assistantQueries.getAssistants());
    return (
      <DashboardProvider assistants={data}>
        <LayoutComponent />
      </DashboardProvider>
    );
  },
  loader: ({ context }) => {
    return context.queryClient.ensureQueryData(
      assistantQueries.getAssistants(),
    );
  },
});

function LayoutComponent() {
  const isMobile = useDashboardContext().isMobile;
  const sidebarVisible = useDashboardContext().sidebarVisible;
  const assistants = useDashboardContext().assistants;
  const user = useAuth().user;

  const currentAssistantId = useParams({ strict: false }).assistantId;

  const primaryLinks: NavLink[] = [
    {
      title: "Ассистент",
      icon: BotIcon,
      label: String(assistants.length),
      variant: "ghost",
      link: (children, className) => (
        <MatchRoute
          to={
            assistants.length > 0 ? "/assistants/$assistantId" : "/assistants"
          }
        >
          {(match) => (
            <Link className={className?.(!!match)} to="/assistants">
              {children}
            </Link>
          )}
        </MatchRoute>
      ),
      showOnMobile: true,
    },
    {
      title: "Чаты",
      icon: MessageCircleIcon,
      variant: "ghost",
      link: (children, className) => (
        <MatchRoute to="/assistants/$assistantId/chats">
          {(match) =>
            assistants.length > 0 ? (
              <Link
                className={className?.(!!match)}
                to="/assistants/$assistantId/chats"
                params={{
                  assistantId: currentAssistantId || String(assistants[0].id),
                }}
              >
                {children}
              </Link>
            ) : (
              <Link className={className?.(!!match)} to="/assistants">
                {children}
              </Link>
            )
          }
        </MatchRoute>
      ),
      showOnMobile: true,
    },
    {
      title: "Плагины",
      label: "2",
      icon: BlocksIcon,
      variant: "ghost",
      link: (children, className) => (
        <MatchRoute to="/assistants/$assistantId/plugins" fuzzy={true}>
          {(match) =>
            assistants.length > 0 ? (
              <Link
                className={className?.(!!match)}
                to="/assistants/$assistantId/plugins"
                params={{
                  assistantId: currentAssistantId || String(assistants[0].id),
                }}
              >
                {children}
              </Link>
            ) : (
              <Link className={className?.(!!match)} to="/assistants">
                {children}
              </Link>
            )
          }
        </MatchRoute>
      ),
      showOnMobile: true,
    },
    {
      title: "Статистика",
      icon: BarChartIcon,
      variant: "ghost",
      link: (children, className) => (
        <MatchRoute to="/assistants/$assistantId/statistics">
          {(match) =>
            assistants.length > 0 ? (
              <Link
                className={className?.(!!match)}
                to="/assistants/$assistantId/statistics"
                params={{
                  assistantId: currentAssistantId || String(assistants[0].id),
                }}
              >
                {children}
              </Link>
            ) : (
              <Link className={className?.(!!match)} to="/assistants">
                {children}
              </Link>
            )
          }
        </MatchRoute>
      ),
      showOnMobile: true,
    },
  ];

  const secondaryLinks: NavLink[] = [
    {
      title: "Настройки",
      icon: SettingsIcon,
      variant: "ghost",
      link: (children, className) => (
        <MatchRoute to="/assistants/$assistantId/settings">
          {(match) =>
            assistants.length > 0 ? (
              <Link
                className={className?.(!!match)}
                to="/assistants/$assistantId/settings"
                params={{
                  assistantId: currentAssistantId || String(assistants[0].id),
                }}
              >
                {children}
              </Link>
            ) : (
              <Link className={className?.(!!match)} to="/assistants">
                {children}
              </Link>
            )
          }
        </MatchRoute>
      ),
      showOnMobile: true,
    },
    {
      title: "Баланс",
      label: `${user?.balance.toFixed(2)} $`,
      icon: CoinsIcon,
      variant: "ghost",
      link: (children, className) => (
        <MatchRoute to="/assistants">
          {(match) =>
            assistants.length > 0 ? (
              <Link
                className={className?.(!!match)}
                to="/assistants/$assistantId/settings"
                search={{ tab: "billing" }}
                params={{
                  assistantId: currentAssistantId || String(assistants[0].id),
                }}
              >
                {children}
              </Link>
            ) : (
              <Link className={className?.(!!match)} to="/assistants">
                {children}
              </Link>
            )
          }
        </MatchRoute>
      ),
    },
  ];

  if (isMobile) {
    return (
      <div
        className={cn("flex min-h-svh w-full flex-col relative", {
          "pb-14": sidebarVisible,
        })}
      >
        <Outlet />
        <aside
          className={cn(
            "fixed bottom-0 left-0 right-0 border-t bg-background/20 backdrop-blur transition-all",
            {
              "-bottom-full": !sidebarVisible,
            },
          )}
        >
          <Nav
            isCollapsed={true}
            links={[...primaryLinks, ...secondaryLinks].filter(
              (link) => link.showOnMobile,
            )}
            direction="horizontal"
          />
        </aside>
      </div>
    );
  }

  return (
    <div className="flex h-svh w-full flex-col">
      <ResizablePanelGroup
        autoSaveId="resizable-panels:layout"
        direction="horizontal"
        className="w-full h-full items-stretch"
      >
        <Sidebar primaryLinks={primaryLinks} secondaryLinks={secondaryLinks} />
        <ResizablePanel
          id="content"
          order={2}
          defaultSize={80}
          minSize={80}
          maxSize={96}
        >
          <Outlet />
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
}
