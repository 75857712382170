import React from "react";
import { User } from "@/schemas/user.ts";

export type AuthContext = {
  logout: () => Promise<void>;
  user: User | undefined | null;
  getStatus: () => "authenticated" | "unauthenticated" | "loading";
};

export const AuthContext = React.createContext<AuthContext | undefined>(
  undefined,
);

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
