import { createFileRoute, Navigate, Outlet } from "@tanstack/react-router";
import { AssistantProvider } from "@/features/dashboard/assistant/assistant.provider.tsx";
import { assistantQueries } from "@/services/queries/assistant.ts";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Assistant } from "@/schemas/assistant.ts";

export const Route = createFileRoute(
  "/_authenticated/_dashboard/assistants/$assistantId/_assistantProvider",
)({
  component: () => {
    const id = Route.useParams().assistantId;

    if (isNaN(+id)) {
      return <Navigate to="/assistants" />;
    }

    return <AssistantLayout key={id} id={+id} />;
  },
});

type AssistantLayoutParams = {
  id: Assistant["id"];
};

function AssistantLayout({ id }: AssistantLayoutParams) {
  const { data: assistantData } = useSuspenseQuery(
    assistantQueries.getAssistantById(+id),
  );

  return (
    <AssistantProvider assistant={assistantData}>
      <Outlet />
    </AssistantProvider>
  );
}
