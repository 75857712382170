import { Assistant } from "@/schemas/assistant.ts";
import { queryOptions } from "@tanstack/react-query";
import { statisticsAPI } from "@/services/api/statistics.ts";

class StatisticsQueries {
  getStatistics = (assistant_id: Assistant["id"]) => {
    return queryOptions({
      queryKey: ["statistics", assistant_id],
      queryFn: () => statisticsAPI.getStatistics(assistant_id),
      staleTime: Infinity,
    });
  };
}

export const statisticsQueries = new StatisticsQueries();
