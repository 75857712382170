import { axiosInstance } from "@/services/api/api.ts";
import { Assistant } from "@/schemas/assistant.ts";
import {
  clientsPlaygroundHistoryPropsSchema,
  clientsPlaygroundHistoryRespSchema,
  Playground,
} from "@/schemas/clients.ts";
import { z } from "zod";

class ClientsAPI {
  private api = axiosInstance("assistants");

  getPlayground = async (assistant_id: Assistant["id"]) => {
    const response = await this.api.get<Playground>(
      `/${assistant_id}/clients/playground/`,
    );
    return response.data;
  };

  getPlaygroundHistory = async (
    assistant_id: Assistant["id"],
    params: z.infer<typeof clientsPlaygroundHistoryPropsSchema>,
  ) => {
    if (clientsPlaygroundHistoryPropsSchema.safeParse(params).error) {
      throw new Error("Введите правильные данные");
    }

    const response = await this.api.get<
      z.infer<typeof clientsPlaygroundHistoryRespSchema>
    >(`/${assistant_id}/clients/playground/history/`, {
      params,
    });

    return response.data;
  };
}

export const clientsAPI = new ClientsAPI();
