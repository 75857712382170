import { z } from "zod";
import {
  paginationParamsSchema,
  paginationRespSchema,
  phoneNumberSchema,
} from "@/schemas/shared.ts";
import { assistantSchema } from "@/schemas/assistant.ts";
import { messangerTypes } from "@/schemas/system.ts";
import { userSchema } from "@/schemas/user.ts";

export const messageSchema = z.object({
  cost: z.number(),
  sender: z.union([z.literal("bot"), z.string()]),
  message: z.string(),
  timestamp: z.string().transform((value) => new Date(value)),
  status: z
    .union([z.literal("success"), z.literal("error"), z.literal("pending")])
    .optional()
    .transform((value) => value || "success"),
  error: z.string().optional(),
});

export type Message = z.infer<typeof messageSchema>;

export const clientsPlaygroundRespSchema = z.object({
  id: userSchema.shape.id,
  messanger_type: z.array(messangerTypes),
  username: z.string(),
  phone_number: phoneNumberSchema,
  interacted_date: z.string().transform((value) => new Date(value)),
  updated_date: z.string().transform((value) => new Date(value)),
  gpt_active: z.boolean(),
  token_expenses_in: z.number(),
  token_expenses_out: z.number(),
  assistant: assistantSchema.shape.id,
});

export type Playground = z.infer<typeof clientsPlaygroundRespSchema>;

export const clientsPlaygroundHistoryPropsSchema = paginationParamsSchema;

export const clientsPlaygroundHistoryRespSchema = paginationRespSchema.extend({
  results: z.array(messageSchema),
});

export type PlaygroundHistory = z.infer<
  typeof clientsPlaygroundHistoryRespSchema
>;
