import React, { Suspense } from 'react';

const LazyRouterDevtools = process.env.NODE_ENV === 'production'
	? () => null
	: React.lazy(() =>
		import('@tanstack/router-devtools').then((res) => ({
			default: res.TanStackRouterDevtools,
		})),
	)

const RouterDevtools: React.FC = () => {
	return (
		<Suspense fallback={null}>
			<LazyRouterDevtools />
		</Suspense>
	);
};

export default RouterDevtools;
