import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";
import { Assistant } from "@/schemas/assistant.ts";
import { z } from "zod";
import { clientsPlaygroundHistoryRespSchema } from "@/schemas/clients.ts";
import { clientsAPI } from "@/services/api/clients.ts";
import { getPageNumber } from "@/lib/pagination.ts";

const LIMIT = 15;

class ClientsQueries {
  playground = (assistant_id: Assistant["id"]) => {
    return queryOptions({
      queryKey: ["clients", "playground", assistant_id],
      queryFn: () => clientsAPI.getPlayground(assistant_id),
    });
  };

  playgroundHistory = (assistant_id: Assistant["id"]) => {
    return infiniteQueryOptions<
      z.infer<typeof clientsPlaygroundHistoryRespSchema>
    >({
      queryKey: ["clients", "playground", "history", assistant_id],
      queryFn: async ({ pageParam }) => {
        return await clientsAPI.getPlaygroundHistory(assistant_id, {
          limit: LIMIT,
          offset:
            isNaN(Number(pageParam)) || Number(pageParam) <= 1
              ? undefined
              : (Number(pageParam) - 1) * LIMIT,
        });
      },
      initialPageParam: 1,
      getPreviousPageParam: (firstPage) => {
        if (!firstPage || !firstPage.previous) return undefined;
        const page = getPageNumber({
          url: firstPage.previous,
          pattern: "offset=",
          divider: LIMIT,
        });

        return page ? page + 1 : undefined;
      },
      getNextPageParam: (lastPage) => {
        if (!lastPage || !lastPage.next) return undefined;
        const page = getPageNumber({
          url: lastPage.next,
          pattern: "offset=",
          divider: LIMIT,
        });

        return page ? page + 1 : undefined;
      },
      staleTime: Infinity,
    });
  };
}

export const clientsQueries = new ClientsQueries();
