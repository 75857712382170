import { createFileRoute } from "@tanstack/react-router";
import { assistantQueries } from "@/services/queries/assistant.ts";
import AssistantLoadingPage from "@/features/dashboard/assistant/assistant.loading.tsx";

export const Route = createFileRoute(
  "/_authenticated/_dashboard/assistants/$assistantId/_assistantProvider/",
)({
  loader: ({ context, params }) => {
    return context.queryClient.ensureQueryData(
      assistantQueries.getAssistantById(+params.assistantId),
    );
  },
  pendingComponent: AssistantLoadingPage,
});
