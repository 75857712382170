import React, { Suspense } from "react";
import useMediaQuery from "@/hooks/useMediaQuery.ts";
import { useTheme } from "@/features/layout/theme";
import { cn } from "@/lib/utils.ts";
import { LogoIcon } from "@/features/icons/logo.icon.tsx";
import { ErrorBoundary } from "react-error-boundary";
import { TABLET_BREAKPOINT } from "@/features/shared/constants.ts";

const PerlineNoiseSphere = React.lazy(
  () => import("@/features/auth/perline-noise-sphere.tsx"),
);

type AuthLayoutProps = React.PropsWithChildren & {
  asideClassName?: string;
};

const AuthLayoutComponent: React.FC<AuthLayoutProps> = ({
  children,
  asideClassName,
}) => {
  const isMobile = useMediaQuery(TABLET_BREAKPOINT);
  const { resolvedTheme } = useTheme();
  return (
    <div className="w-full h-svh flex relative p-1">
      {!isMobile && (
        <aside
          className={cn(
            "w-full max-w-screen-md min-h-full relative isolate rounded-lg text-lg",
            {
              "bg-black text-white": resolvedTheme === "light",
              "bg-background text-foreground": resolvedTheme === "dark",
            },
            asideClassName,
          )}
        >
          <div className="absolute -z-[1] left-0 right-0 top-1/2 -translate-y-1/2 aspect-square">
            <ErrorBoundary fallback={null}>
              <Suspense fallback={null}>
                <PerlineNoiseSphere />
              </Suspense>
            </ErrorBoundary>
          </div>
          <div className="w-full h-full p-12 flex flex-col items-start justify-between">
            <div className="flex items-center gap-2">
              <LogoIcon className="h-7 w-7" />
              <span>Numa AI</span>
            </div>
            <div>
              <p>
                “Лучшее решение для бизнеса, которое я принимал за последнее
                время”
              </p>
              <p className="text-sm">CEO Tayyab</p>
            </div>
          </div>
        </aside>
      )}
      <section className="w-full max-h-full relative overflow-y-scroll p-1">
        {children}
      </section>
    </div>
  );
};

export default AuthLayoutComponent;
