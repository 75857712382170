import { cn } from "@/lib/utils.ts";
import React from "react";
import { Separator } from "@/features/ui/separator.tsx";
import { Link } from "@tanstack/react-router";
import { Button } from "@/features/ui/button.tsx";
import { ArrowLeftIcon } from "lucide-react";
import { useDashboardContext } from "@/features/dashboard/dashboard.provider.tsx";

const AssistantSwitcherDrawer = React.lazy(
  () => import("@/features/layout/assistant-switcher-drawer.tsx"),
);

type SectionHeaderProps = React.PropsWithChildren & {
  className?: string;
  leadingIcon?: "assistant" | "back" | React.ReactNode;
  endingIcon?: React.ReactNode;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({
  children,
  className,
  leadingIcon,
  endingIcon,
}) => {
  const isMobile = useDashboardContext().isMobile;
  const renderLeadingIcon = () => {
    if (leadingIcon === "assistant") {
      return <AssistantSwitcherDrawer />;
    }

    if (leadingIcon === "back") {
      return (
        <Button variant="ghost" size="icon" asChild>
          <Link to="..">
            <ArrowLeftIcon className="h-4 w-4" />
          </Link>
        </Button>
      );
    }

    if (leadingIcon) {
      return leadingIcon;
    }

    return <div className="w-9 h-9" />;
  };

  return (
    <>
      <div
        className={cn(
          "flex h-[52px] min-h-[52px] items-center justify-between p-2",
          className,
        )}
      >
        {isMobile && renderLeadingIcon()}
        {children}
        {isMobile && (endingIcon ? endingIcon : <div className="w-9 h-9" />)}
      </div>
      <Separator />
    </>
  );
};

export default SectionHeader;
