import { Button } from "@/features/ui/button.tsx";
import { ErrorRouteComponent, Link } from "@tanstack/react-router";

const ErrorComponent: ErrorRouteComponent = ({ error }) => {
  return (
    <section className="h-svh w-full flex flex-col items-center justify-center gap-2 text-center">
      <h1 className="text-2xl font-bold">Произошла ошибка</h1>
      <p className="text-destructive">{error.message}</p>
      <div className="space-x-2">
        <Button variant="secondary" onClick={() => location.reload()}>
          Обновить страницу
        </Button>
        <Button asChild>
          <Link to="/assistants">Вернуться на главную</Link>
        </Button>
      </div>
    </section>
  );
};

export default ErrorComponent;
