import React from "react";
import { Assistant } from "@/schemas/assistant.ts";

const AssistantContext = React.createContext<Assistant | undefined>(undefined);

type AssistantProviderProps = React.PropsWithChildren & {
  assistant: Assistant;
};

export const AssistantProvider: React.FC<AssistantProviderProps> = ({
  children,
  assistant,
}) => {
  React.useEffect(() => {
    localStorage.setItem("assistant-id", String(assistant.id));
  }, [assistant]);

  return (
    <AssistantContext.Provider value={assistant}>
      {children}
    </AssistantContext.Provider>
  );
};

export const useAssistantContext = () => {
  const context = React.useContext(AssistantContext);
  if (!context) {
    throw new Error(
      "useAssistantContext must be used within an AssistantProvider",
    );
  }
  return context;
};
