import { Button } from "@/features/ui/button.tsx";
import { Link } from "@tanstack/react-router";
import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";

const PerlineNoiseSphere = React.lazy(
  () => import("@/features/auth/perline-noise-sphere.tsx"),
);

const NotFound = () => {
  return (
    <main className="flex h-screen w-full flex-col items-center justify-center gap-2">
      <div className="flex items-center justify-center text-9xl font-black -space-x-8">
        <span>4</span>
        <div className="w-48 aspect-square text-center flex items-center justify-center gap-2">
          <ErrorBoundary fallback={<span>0</span>}>
            <Suspense fallback={<span>0</span>}>
              <PerlineNoiseSphere size={200} density={23} />
            </Suspense>
          </ErrorBoundary>
        </div>
        <span className="z-10">4</span>
      </div>
      <p className="text-center">Страница не найдена</p>
      <Button asChild>
        <Link to="/">Вернуться на главную</Link>
      </Button>
    </main>
  );
};

export default NotFound;
