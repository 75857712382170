import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const searchParamsSchema = z.object({
  code: z.string(),
});

export const Route = createFileRoute("/chats/")({
  validateSearch: (search) => searchParamsSchema.parse(search),
});
