import { z } from "zod";

// gpt-models

export const gptModelSchema = z.object({
  id: z.number().readonly(),
  description: z.string(),
  input_cost: z.number(),
  name: z.string(),
  output_cost: z.number(),
});

export type GPTModel = z.infer<typeof gptModelSchema>;

// messanger-types

export const messangerTypes = z.union([
  z.literal("telegram"),
  z.literal("whatsapp"),
  z.literal("playground"),
]);

// features

export const featuresNameSchema = z.union([
  z.literal("interruption"),
  z.literal("send_media"),
  z.literal("file_search"),
  z.literal("reminder"),
]);

const availableFeaturesSchema = z.object({
  id: z.number(),
  name: featuresNameSchema,
});

export type AvailableFeatures = z.infer<typeof availableFeaturesSchema>;

// services

const availableServicesSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export type AvailableServices = z.infer<typeof availableServicesSchema>;
