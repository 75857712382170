import useHideSidebar from "@/hooks/useHideSidebar.ts";
import GptChatHeader from "@/features/dashboard/assistant/playground/gpt-chat/gpt-chat.header.tsx";
import MessagesLoading from "@/features/dashboard/shared/chat/messages.loading.tsx";

const GptChatLoading = () => {
  useHideSidebar();

  return (
    <div className="h-full flex flex-col">
      <GptChatHeader />
      <div className="flex-1 flex flex-col p-2">
        <MessagesLoading />
      </div>
    </div>
  );
};

export default GptChatLoading;
