import { mutationOptions } from "@/lib/mutation";
import { handleMessageAPI } from "@/services/api/messages.ts";

class MessagesMutations {
  handleMessage = () => {
    return mutationOptions({
      mutationKey: ["handle-message"],
      mutationFn: handleMessageAPI.handleMessage,
    });
  };

  clearLogs = () => {
    return mutationOptions({
      mutationKey: ["clearLogs"],
      mutationFn: handleMessageAPI.clearLogs,
    });
  };
}

export const messagesMutations = new MessagesMutations();
