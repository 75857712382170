import { queryOptions } from "@tanstack/react-query";
import { assistantAPI } from "@/services/api/assistant.ts";

class AssistantQueries {
  getAssistants = () => {
    return queryOptions({
      queryKey: ["assistants"],
      queryFn: assistantAPI.getAssistants,
      staleTime: Infinity,
    });
  };

  getAssistantById = (id: number) => {
    return queryOptions({
      queryKey: ["assistants", id],
      queryFn: () => assistantAPI.getAssistantById(id),
      staleTime: Infinity,
    });
  };
}

export const assistantQueries = new AssistantQueries();
