import SectionHeader from "@/features/layout/section-header.tsx";
import { Button } from "@/features/ui/button.tsx";
import { BotMessageSquare } from "lucide-react";
import { Skeleton } from "@/features/ui/skeleton.tsx";

const PlaygroundLoading = () => {
  return (
    <div className="h-full flex flex-col">
      <SectionHeader
        leadingIcon="assistant"
        endingIcon={
          <Button variant="ghost" size="icon" asChild>
            <BotMessageSquare className="h-5 w-5" />
          </Button>
        }
      >
        <span className="text-xl font-bold">Настройки ассистента</span>
      </SectionHeader>
      <div className="flex-1 p-2 flex flex-col gap-4">
        <div>
          <Skeleton className="w-32 h-5 mb-2" />
          <Skeleton className="w-full h-10" />
        </div>
        <div>
          <Skeleton className="w-24 h-5 mb-2" />
          <Skeleton className="w-full h-32" />
        </div>
        <div>
          <Skeleton className="w-24 h-5 mb-2" />
          <Skeleton className="w-full h-9" />
        </div>

        <div className="mt-12">
          <Skeleton className="w-32 h-5 mb-2" />
          <Skeleton className="w-full h-10" />
        </div>
        <div>
          <Skeleton className="w-24 h-5 mb-2" />
          <Skeleton className="w-full h-32" />
        </div>
        <div>
          <Skeleton className="w-24 h-5 mb-2" />
          <Skeleton className="w-full h-9" />
        </div>
      </div>
    </div>
  );
};

export default PlaygroundLoading;
