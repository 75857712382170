import Message from "@/features/dashboard/shared/chat/message.tsx";
import { Skeleton } from "@/features/ui/skeleton.tsx";

const MessagesLoading = () => {
  return new Array(10).fill(1).map((_, i) => (
    <Message handleSend={() => {}} key={i} from={i % 2 === 0 ? "them" : "me"}>
      {i % 1 === 0 && <Skeleton className="w-72 h-24" />}
      {i % 2 === 0 && <Skeleton className="w-48 h-5 mt-2 ml-auto" />}
      {i % 3 === 0 && <Skeleton className="w-24 h-5 mt-2 ml-auto" />}
    </Message>
  ));
};

export default MessagesLoading;
