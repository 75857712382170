import AuthLayoutComponent from "@/features/auth/layout.tsx";
import { createFileRoute, Outlet } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth")({
  component: () => {
    return (
      <AuthLayoutComponent>
        <Outlet />
      </AuthLayoutComponent>
    );
  },
});
