import { axiosInstance } from "@/services/api/api.ts";
import { z } from "zod";
import {
  clearLogsPropsSchema,
  clearLogsRespSchema,
  handleMessagePropsSchema,
  handleMessageRespSchema,
} from "@/schemas/messages.ts";

class HandleMessageAPI {
  private api = axiosInstance("messages");

  handleMessage = async (data: z.infer<typeof handleMessagePropsSchema>) => {
    if (handleMessagePropsSchema.safeParse(data).error) {
      throw new Error("Введите правильные данные");
    }

    const response = await this.api.post<
      z.infer<typeof handleMessageRespSchema>
    >("/handle-message/", data);
    return response.data;
  };

  clearLogs = async (data: z.infer<typeof clearLogsPropsSchema>) => {
    if (clearLogsPropsSchema.safeParse(data).error) {
      throw new Error("Введите правильные данные");
    }

    const response = await this.api.post<z.infer<typeof clearLogsRespSchema>>(
      "/clear-logs/",
      data,
    );
    return response.data;
  };
}

export const handleMessageAPI = new HandleMessageAPI();
