import { z } from "zod";
import { userSchema } from "@/schemas/user.ts";
import { assistantSchema } from "@/schemas/assistant.ts";

// handle message

export const handleMessagePropsSchema = z.object({
  assistant_id: z.number().int(),
  message: z.string().min(1),
});

export const handleMessageRespSchema = z.object({
  content: z.string(),
  tool_outputs: z.array(z.string()),
  completion_tokens: z.number(),
  prompt_tokens: z.number(),
  total_tokens: z.number(),
  cost: z.number(),
  client_id: userSchema.shape.id,
});

// generate instruction

export const generateInstructionPropsSchema = z.object({
  text: z.string().min(1, { message: "Введите текст" }),
});

export const generateInstructionRespSchema = z.object({
  instruction: z.string(),
});

// clear logs

export const clearLogsPropsSchema = z.object({
  client_id: userSchema.shape.id,
  assistant_id: assistantSchema.shape.id,
});

export const clearLogsRespSchema = z.object({
  message: z.string(),
});
