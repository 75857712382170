import { axiosInstance } from "@/services/api/api.ts";
import {
  Assistant,
  createAssistantRespSchema,
  createAssistantSchema,
  updateAssistantSchema,
} from "@/schemas/assistant.ts";
import { z } from "zod";

class AssistantAPI {
  private api = axiosInstance("assistants", true);

  getAssistants = async () => {
    const response = await this.api.get<Assistant[]>(`/`);
    return response.data;
  };

  createAssistant = async (params: z.infer<typeof createAssistantSchema>) => {
    if (createAssistantSchema.safeParse(params).error) {
      throw new Error("Введите правильные данные");
    }

    const response = await this.api.post<
      z.infer<typeof createAssistantRespSchema>
    >("/", params);
    return response.data;
  };

  getAssistantById = async (id: Assistant["id"]) => {
    const response = await this.api.get<Assistant>(`/${id}/`);
    return response.data;
  };

  updateAssistantById = async (
    id: number,
    params: z.infer<typeof updateAssistantSchema>,
  ) => {
    if (updateAssistantSchema.safeParse(params).error) {
      throw new Error("Введите правильные данные");
    }

    const response = await this.api.patch<Assistant>(`/${id}/`, params);
    return response.data;
  };

  deleteAssistantById = async (id: Assistant["id"]) => {
    const response = await this.api.delete(`/${id}/`);
    return response.data;
  };
}

export const assistantAPI = new AssistantAPI();
