import React from "react";
import { usePostHog } from "posthog-js/react";
import { useLocation } from "@tanstack/react-router";

const Pageview: React.FC = () => {
  const posthog = usePostHog();
  const location = useLocation();

  React.useEffect(() => {
    if (import.meta.env.PROD) {
      posthog.capture("$pageview", {
        $current_url: window.origin + location.href,
      });
    }
  }, [location]);

  return null;
};

export default Pageview;
