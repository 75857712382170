import { createFileRoute } from "@tanstack/react-router";
import { clientsQueries } from "@/services/queries/clients.ts";

export const Route = createFileRoute(
  "/_authenticated/_dashboard/assistants/$assistantId/_assistantProvider/playground/",
)({
  loader: ({ context, params }) => {
    return context.queryClient.ensureInfiniteQueryData(
      clientsQueries.playgroundHistory(Number(params.assistantId)),
    );
  },
});
