import {
  createFileRoute,
  Navigate,
  Outlet,
  redirect,
  RoutePaths,
} from "@tanstack/react-router";
import { routeTree } from "@/routeTree.gen.ts";
import { useAuth } from "@/features/layout/auth/auth.context.ts";

export const Route = createFileRoute("/_authenticated")({
  beforeLoad: ({ context, location }) => {
    if (context.auth.getStatus() === "unauthenticated") {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href as RoutePaths<typeof routeTree>,
        },
      });
    }
  },
  component: AuthLayout,
});

function AuthLayout() {
  const auth = useAuth();

  if (auth.getStatus() === "loading") {
    return (
      <div className="w-full min-h-screen flex items-center justify-center">
        Авторизация...
      </div>
    );
  }

  if (auth.getStatus() === "unauthenticated") {
    return (
      <Navigate
        to="/login"
        search={{
          redirect: window.location.pathname as RoutePaths<typeof routeTree>,
        }}
      />
    );
  }

  return <Outlet />;
}
