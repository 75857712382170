import { z } from "zod";

export const settingsSearchParamsSchema = z.object({
  tab: z.enum(["account", "appearance", "billing"]).optional(),
});

export const settingsTab = z.object({
  tab: settingsSearchParamsSchema.shape.tab.default("account"),
  label: z.string(),
});

export type SettingsTab = z.infer<typeof settingsTab>;
