import * as React from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";

import { cn } from "@/lib/utils";

const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root> & {
    text?: string;
  }
>(
  (
    {
      className,
      orientation = "horizontal",
      text,
      decorative = true,
      ...props
    },
    ref,
  ) => (
    <div
      className={cn("relative flex items-center", className, {
        "py-4": !!text,
      })}
    >
      <SeparatorPrimitive.Root
        ref={ref}
        decorative={decorative}
        orientation={orientation}
        className={cn(
          "shrink-0 bg-border",
          orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
          text ? "relative" : "",
          className,
        )}
        {...props}
      />
      {text && (
        <span
          className={cn(
            "absolute bg-background px-2 text-sm text-accent",
            orientation === "horizontal"
              ? "left-1/2 -translate-x-1/2"
              : "top-1/2 -translate-y-1/2",
            orientation === "horizontal" ? "-mt-[2px]" : "-ml-[2px]",
          )}
        >
          {text}
        </span>
      )}
    </div>
  ),
);
Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator };
