import React from "react";
import { Button, buttonVariants } from "@/features/ui/button.tsx";
import { PaintbrushIcon } from "lucide-react";
import { VariantProps } from "class-variance-authority";
import { messagesMutations } from "@/services/mutations/messages.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAssistantContext } from "@/features/dashboard/assistant/assistant.provider.tsx";
import { clientsQueries } from "@/services/queries/clients.ts";
import { Playground } from "@/schemas/clients.ts";

type ClearGptChatButtonProps = VariantProps<typeof buttonVariants> & {
  client_id?: Playground["id"];
};

const ClearGptChatButton: React.FC<ClearGptChatButtonProps> = ({
  variant,
  size,
  client_id,
}) => {
  const { mutate, status } = useMutation(messagesMutations.clearLogs());
  const assistant = useAssistantContext();

  const queryClient = useQueryClient();

  const handleClear = () => {
    if (!client_id) return;

    mutate(
      {
        assistant_id: assistant.id,
        client_id: client_id!,
      },
      {
        onSuccess: async () => {
          queryClient.setQueryData(
            clientsQueries.playgroundHistory(assistant.id).queryKey,
            (oldData) => {
              if (!oldData) return undefined;

              return {
                ...oldData,
                pages: [],
              };
            },
          );

          await queryClient.invalidateQueries({
            queryKey: clientsQueries.playground(assistant.id).queryKey,
          });
        },
      },
    );
  };

  return (
    <Button
      onClick={handleClear}
      variant={variant}
      size={size}
      disabled={status === "pending" || !client_id}
      status={status}
    >
      <PaintbrushIcon className="h-4 w-4" />
    </Button>
  );
};

export default ClearGptChatButton;
