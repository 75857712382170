import { createFileRoute, Navigate } from "@tanstack/react-router";
import { z } from "zod";
import { phoneNumberSchema } from "@/schemas/shared.ts";
import { verifySmsPropsSchema } from "@/schemas/auth.ts";

export const verifySmsSearchParamsSchema = z.object({
  p: phoneNumberSchema,
  uid: verifySmsPropsSchema.shape.uid,
  r_id: verifySmsPropsSchema.shape.registration_id,
  reset: verifySmsPropsSchema.shape.is_password_reset,
});

export const Route = createFileRoute("/_auth/code/")({
  validateSearch: (search) => verifySmsSearchParamsSchema.parse(search),
  errorComponent: () => <Navigate to="/login" />,
});
