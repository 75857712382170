import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { CircleLoader } from "@/features/icons/circle-loader.icon.tsx";
import { MutationStatus } from "@tanstack/react-query";

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-1 whitespace-nowrap text-sm rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground shadow hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        destructiveSecondary:
          "bg-destructive/10 text-destructive hover:bg-destructive hover:text-destructive-foreground",
        outline:
          "border border-input bg-background shadow-sm hover:bg-secondary hover:text-secondary-foreground",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        ghost: "hover:bg-secondary hover:text-secondary-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        unstyled: "unset",
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-9 w-9",
        sm_icon: "h-7 w-7",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  status?: MutationStatus;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      children,
      disabled,
      status,
      ...props
    },
    ref,
  ) => {
    const isPending = status === "pending";
    if (asChild) {
      return (
        <Slot ref={ref} {...props}>
          <>
            {React.Children.map(
              children as React.ReactElement,
              (child: React.ReactElement) => {
                return React.cloneElement(child, {
                  className: cn(buttonVariants({ variant, size }), className),
                  children: (
                    <>
                      {isPending && (
                        <span className="absolute inset-0 flex items-center justify-center">
                          <CircleLoader className="spinner" />
                        </span>
                      )}
                      {child.props.children}
                    </>
                  ),
                });
              },
            )}
          </>
        </Slot>
      );
    }
    return (
      <button
        className={cn(buttonVariants({ variant, size, className }), {
          "error-bounce": status === "error",
        })}
        ref={ref}
        disabled={isPending || disabled}
        {...props}
      >
        {status ? (
          <div className="relative inline-flex items-center justify-center">
            <div
              className={cn("inline-flex items-center gap-1", {
                "opacity-0 invisible": isPending,
              })}
            >
              {children}
            </div>
            {isPending && (
              <span className="absolute inset-0 flex items-center justify-center">
                <CircleLoader className="spinner" />
              </span>
            )}
          </div>
        ) : (
          children
        )}
      </button>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
