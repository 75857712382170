import React from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils.ts";
import { MutationStatus } from "@tanstack/react-query";
import { CircleLoader } from "@/features/icons/circle-loader.icon.tsx";
import Markdown from "react-markdown";
import { AlertCircleIcon } from "lucide-react";
import ResponsiveResend from "@/features/dashboard/shared/chat/responsive-resend.tsx";

const messageVariants = cva(
  "w-fit flex flex-col max-w-96 min-w-24 break-words isolate relative p-2 border rounded-lg",
  {
    variants: {
      from: {
        me: "ml-auto right-tail",
        them: "bg-secondary left-tail",
      },
    },
    defaultVariants: {
      from: "me",
    },
  },
);

type MessageProps = VariantProps<typeof messageVariants> & {
  status?: MutationStatus;
  message?: string;
  timestamp?: string | Date;
  children?: React.ReactNode;
  handleSend: (message: string) => void;
  error?: string;
};

const Message: React.FC<MessageProps> = ({
  from,
  message,
  timestamp,
  children,
  status,
  handleSend,
  error,
}) => {
  if (children) {
    return <div className={cn(messageVariants({ from }))}>{children}</div>;
  }

  const Slot = status === "error" ? ResponsiveResend : "div";

  return (
    <Slot
      {...(status === "error" && {
        message: { message: message!, timestamp: timestamp! },
        handleSend,
        error,
      })}
    >
      <div className="flex items-center gap-2">
        <div className={cn(messageVariants({ from }))}>
          {from === "them" && status === "pending" ? (
            <div id="wave" className="bubble">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          ) : (
            <>
              <Markdown className="text-start">{message || ""}</Markdown>
              <div className="flex items-center gap-1">
                <span className="text-accent text-xs ml-auto">
                  {new Date(timestamp || new Date()).toLocaleTimeString(
                    "ru-RU",
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    },
                  )}
                </span>
                {status === "pending" && (
                  <CircleLoader className="spinner w-3 h-3" />
                )}
              </div>
            </>
          )}
        </div>
        {status === "error" && (
          <AlertCircleIcon className="text-destructive w-4 h-4" />
        )}
      </div>
    </Slot>
  );
};

export default Message;
