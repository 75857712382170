import { z } from "zod";

export const phoneNumberFormSchema = z
  .string()
  .regex(/\+7 \(7\d{2}\) \d{3}-\d{2}-\d{2}/, { message: "" });

export const phoneNumberSchema = z.string({ message: "Введите номер" });

export const tokensRespSchema = z.object({
  access: z.string(),
  refresh: z.string(),
});

export const smsCodeRespSchema = z.object({
  uid: z.string({ message: "Введите UID" }),
  phone_number: phoneNumberSchema,
});

export const passwordFormSchema = z
  .object({
    password: z
      .string()
      .min(8, { message: "Минимум 8 символов" })
      .max(128, { message: "Максимум 128 символов" })
      .regex(/.*[a-z].*/, { message: "Хотя бы одна маленькая буква" })
      .regex(/.*[A-Z].*/, { message: "Хотя бы одна большая буква" })
      .regex(/.*[0-9].*/, { message: "Хотя бы одна цифра" }),
    confirm: z.string().min(1, { message: "Повторите пароль" }),
  })
  .refine((value) => value.password === value.confirm, {
    message: "Пароли не совпадают",
    path: ["confirm"],
  });

export const sortSchema = z.enum(["asc", "desc"]);

export const paginationParamsSchema = z.object({
  limit: z.number().min(1, { message: "Введите лимит" }).optional(),
  offset: z.number().min(1, { message: "Введите отступ" }).optional(),
  order: z.union([z.literal("asc"), z.literal("desc")]).optional(),
});

export const paginationRespSchema = z.object({
  count: z.number(),
  next: z.string().nullable(),
  previous: z.string().nullable(),
});
