type PageNumberOptions = {
  url?: string | null;
  pattern?: string;
  divider?: number;
};

export const getPageNumber = ({
  url,
  pattern = "page=",
  divider = 1,
}: PageNumberOptions): number | undefined => {
  if (!url) return undefined;
  const match = url.match(new RegExp(`${pattern}(\\d+)`));
  return match ? parseInt(match[1], 10) / divider : undefined;
};
