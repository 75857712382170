import React from "react";
import {
  MutationStatus,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { userQueries } from "@/services/queries/user.ts";
import { AuthContext } from "@/features/layout/auth/auth.context.ts";
import { getTokens, removeTokens } from "@/lib/token.ts";
import { usePostHog } from "posthog-js/react";

type AuthProviderProps = React.PropsWithChildren;

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const posthog = usePostHog();
  const queryClient = useQueryClient();
  const { data: user, status } = useQuery({
    ...userQueries.user(),
    retry: 1,
    enabled: () => !!getTokens().refreshToken,
  });

  React.useEffect(() => {
    if (user) {
      posthog.identify(String(user.id), {
        phone_number: user.phone_number,
      });
    } else {
      posthog.reset();
    }
  }, [user]);

  const getStatus = () => getAuthStatus(status);

  const logout = React.useCallback(async () => {
    removeTokens();
    posthog.reset();
    queryClient.setQueryData(userQueries.user().queryKey, null);
    queryClient.removeQueries({ queryKey: userQueries.user().queryKey });
  }, []);

  return (
    <AuthContext.Provider value={{ user, logout, getStatus }}>
      {children}
    </AuthContext.Provider>
  );
};

function getAuthStatus(status: MutationStatus) {
  if (!getTokens().refreshToken || status === "error") return "unauthenticated";

  if (status === "pending") return "loading";

  if (status === "success") return "authenticated";

  return "unauthenticated";
}

export type AuthStatus = ReturnType<typeof getAuthStatus>;

export default AuthProvider;
