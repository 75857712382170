import React from "react";
import { LucideIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/features/ui/tooltip.tsx";
import { buttonVariants } from "@/features/ui/button.tsx";
import { VariantProps } from "class-variance-authority";
import { useDashboardContext } from "@/features/dashboard/dashboard.provider.tsx";

type NavProps = {
  isCollapsed: boolean;
  direction?: "horizontal" | "vertical";
  links: NavLink[];
};

export type NavLink = {
  title: string;
  label?: string;
  icon: LucideIcon;
  variant: VariantProps<typeof buttonVariants>["variant"];
  link: (
    children: React.ReactNode,
    className?: (isActive: boolean) => string,
  ) => React.ReactNode;
  showOnMobile?: boolean;
};

export const Nav: React.FC<NavProps> = ({
  links,
  isCollapsed,
  direction = "vertical",
}) => {
  const isMobile = useDashboardContext().isMobile;
  return (
    <div
      data-collapsed={isCollapsed}
      className={cn(
        "group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2 w-full",
      )}
    >
      <nav
        className={cn("grid gap-1 px-2", {
          "grid-flow-row": direction === "vertical",
          "grid-flow-col auto-cols-fr items-center": direction === "horizontal",
          "group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2":
            isCollapsed,
        })}
      >
        {links.map((link, index) =>
          isCollapsed ? (
            <Tooltip key={index} delayDuration={0}>
              <TooltipTrigger>
                {link.link(
                  <>
                    <link.icon className="h-4 w-4" />
                    <span className="sr-only">{link.title}</span>
                  </>,
                  (isActive) => {
                    return cn(
                      buttonVariants({
                        variant: isActive ? "default" : link.variant,
                        size: "icon",
                      }),
                      "h-9 w-9",
                      {
                        "w-full": direction === "horizontal",
                      },
                    );
                  },
                )}
              </TooltipTrigger>
              {!isMobile && (
                <TooltipContent
                  side="right"
                  className="flex items-center gap-4"
                >
                  {link.title}
                  {link.label && (
                    <span className="ml-auto text-accent">{link.label}</span>
                  )}
                </TooltipContent>
              )}
            </Tooltip>
          ) : (
            <React.Fragment key={index}>
              {link.link(
                <>
                  <link.icon className="mr-2 h-4 w-4" />
                  {link.title}
                  {link.label && (
                    <span className={cn("ml-auto")}>{link.label}</span>
                  )}
                </>,
                (isActive) => {
                  return cn(
                    buttonVariants({
                      variant: isActive ? "default" : link.variant,
                    }),
                    "justify-start text-sm",
                  );
                },
              )}
            </React.Fragment>
          ),
        )}
      </nav>
    </div>
  );
};
