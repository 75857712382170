import { useDashboardContext } from "@/features/dashboard/dashboard.provider.tsx";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/features/ui/resizable.tsx";
import PlaygroundLoading from "@/features/dashboard/assistant/playground/playground.loading.tsx";
import GptChatLoading from "@/features/dashboard/assistant/playground/gpt-chat/gpt-chat.loading.tsx";

const AssistantLoadingPage = () => {
  const isMobile = useDashboardContext().isMobile;
  if (isMobile) {
    return <PlaygroundLoading />;
  }

  return (
    <ResizablePanelGroup
      autoSaveId="resizable-panels:playground"
      direction="horizontal"
      className="w-full h-full items-stretch"
    >
      <ResizablePanel id="playground" order={2} defaultSize={32} minSize={30}>
        <PlaygroundLoading />
      </ResizablePanel>
      <ResizableHandle withHandle />
      <ResizablePanel id="chat" order={3} defaultSize={48} minSize={30}>
        <GptChatLoading />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};

export default AssistantLoadingPage;
