import { createFileRoute } from "@tanstack/react-router";
import { statisticsQueries } from "@/services/queries/statistics.ts";

export const Route = createFileRoute(
  "/_authenticated/_dashboard/assistants/$assistantId/_assistantProvider/statistics/",
)({
  loader: (ctx) => {
    return ctx.context.queryClient.ensureQueryData(
      statisticsQueries.getStatistics(Number(ctx.params.assistantId)),
    );
  },
});
