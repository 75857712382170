import { mutationOptions } from "@/lib/mutation.ts";
import { assistantAPI } from "@/services/api/assistant.ts";
import { Assistant, updateAssistantSchema } from "@/schemas/assistant.ts";
import { z } from "zod";

class AssistantMutations {
  createAssistant = () => {
    return mutationOptions({
      mutationKey: ["assistants"],
      mutationFn: assistantAPI.createAssistant,
    });
  };

  updateAssistant = (id: Assistant["id"]) => {
    return mutationOptions({
      mutationKey: ["assistants", id],
      mutationFn: (variables: z.infer<typeof updateAssistantSchema>) =>
        assistantAPI.updateAssistantById(id, variables),
    });
  };

  deleteAssistant = () => {
    return mutationOptions({
      mutationKey: ["assistants", "delete"],
      mutationFn: assistantAPI.deleteAssistantById,
    });
  };
}

export const assistantMutations = new AssistantMutations();
