import { createFileRoute, Navigate } from "@tanstack/react-router";
import { z } from "zod";
import { phoneNumberSchema } from "@/schemas/shared.ts";

const searchParamsSchema = z.object({
  p: phoneNumberSchema,
});

export const Route = createFileRoute("/_auth/new-pass/")({
  validateSearch: (search) => searchParamsSchema.parse(search),
  errorComponent: () => <Navigate to="/login" />,
});
