import React from "react";
import { PostHogProvider } from "posthog-js/react";
import { env } from "@/lib/env.ts";
import { PostHogConfig } from "posthog-js";

type PosthogProviderProps = React.PropsWithChildren;

const options: Partial<PostHogConfig> = {
  api_host: env().VITE_POSTHOG_HOST,
  capture_pageview: false,
  autocapture: import.meta.env.PROD,
};

const PosthogProvider: React.FC<PosthogProviderProps> = ({ children }) => {
  return (
    <PostHogProvider apiKey={env().VITE_POSTHOG_KEY} options={options}>
      {children}
    </PostHogProvider>
  );
};

export default PosthogProvider;
