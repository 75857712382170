import { z } from "zod";

const envSchema = z.object({
  VITE_API_URL: z.string().url(),
  VITE_API_PREFIX: z.string().optional(),
  VITE_POSTHOG_KEY: z.string(),
  VITE_POSTHOG_HOST: z.string(),
});

export const env = () => {
  const env = envSchema.safeParse(import.meta.env);
  if (env.success) {
    return env.data;
  }

  throw new Error(env.error.message);
};
