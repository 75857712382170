import React, { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen";
import { useAuth } from "@/features/layout/auth/auth.context.ts";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ResponsiveTooltipProvider } from "@/features/ui/responsive-tooltip.tsx";
import PosthogProvider from "@/features/layout/analytics/posthog.provider.tsx";
import ThemeProvider from "@/features/layout/theme/theme.tsx";
import AuthProvider from "@/features/layout/auth/auth.provider.tsx";
import { initSentry } from "@/lib/sentry.ts";
import { Workbox } from "workbox-window";

import "@/styles/index.css";

const Toaster = React.lazy(() => import("@/features/ui/sonner.tsx"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  context: {
    auth: undefined!,
    queryClient,
  },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

initSentry();

const InnerApp = () => {
  const auth = useAuth();
  return <RouterProvider router={router} context={{ auth }} />;
};

const rootElement = document.getElementById("app")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <PosthogProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <ResponsiveTooltipProvider>
              <AuthProvider>
                <InnerApp />
                <Toaster />
              </AuthProvider>
            </ResponsiveTooltipProvider>
          </ThemeProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </PosthogProvider>
    </StrictMode>,
  );
}

if ("serviceWorker" in navigator) {
  const wb = new Workbox("/sw.js");

  // Force update without user confirmation
  wb.addEventListener("waiting", () => {
    wb.messageSW({ type: "SKIP_WAITING" }); // Skip waiting and activate immediately
  });

  // Reload the page when the new service worker takes control
  wb.addEventListener("controlling", () => {
    window.location.reload();
  });

  wb.register();

  // Ensure the service worker is updated if already registered
  navigator.serviceWorker.getRegistration().then((registration) => {
    if (registration) {
      registration.update(); // Check for updates
    }
  });
}
