import React from "react";
import { useDashboardContext } from "@/features/dashboard/dashboard.provider.tsx";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/features/ui/drawer.tsx";
import { Button } from "@/features/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/features/ui/dropdown-menu.tsx";
import { useQueryClient } from "@tanstack/react-query";
import { useAssistantContext } from "@/features/dashboard/assistant/assistant.provider.tsx";
import { clientsQueries } from "@/services/queries/clients.ts";
import { RefreshCwIcon, Trash2Icon } from "lucide-react";

type ResponsiveResendProps = React.PropsWithChildren<{
  message?: {
    message: string;
    timestamp: string | Date;
  };
  handleSend?: (message: string) => void;
  error?: string;
}>;

const ResponsiveResend: React.FC<ResponsiveResendProps> = ({
  children,
  message,
  handleSend,
  error,
}) => {
  const isMobile = useDashboardContext().isMobile;
  const assistant = useAssistantContext();

  const queryClient = useQueryClient();

  const handleDelete = () => {
    queryClient.setQueryData(
      clientsQueries.playgroundHistory(assistant.id).queryKey,
      (oldData) => {
        if (!oldData) return undefined;
        const newPages = oldData.pages.map((page) => ({
          ...page,
          results: page.results.filter(
            (msg) =>
              !(
                msg.timestamp.toString() === message?.timestamp.toString() &&
                msg.message === message.message
              ),
          ),
        }));

        return {
          ...oldData,
          pages: newPages,
        };
      },
    );
  };

  const handleResend = () => {
    handleDelete();
    handleSend?.(message?.message || "");
  };

  if (isMobile) {
    return (
      <Drawer>
        <DrawerTrigger>{children}</DrawerTrigger>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle>Сообщение не отправлено</DrawerTitle>
            <DrawerDescription className="text-destructive">
              {error || "Попробуйте еще раз"}
            </DrawerDescription>
          </DrawerHeader>
          <DrawerFooter>
            <Button onClick={handleResend} variant="secondary">
              Переотправить
            </Button>
            <Button onClick={handleDelete} variant="destructiveSecondary">
              Удалить
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>{children}</DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel className="text-destructive text-ellipsis line-clamp-1 max-w-prose">
          {error || "Сообщение не отправлено"}
        </DropdownMenuLabel>
        <DropdownMenuItem className="cursor-pointer" onClick={handleResend}>
          <RefreshCwIcon className="h-4 w-4" />
          Переотправить
        </DropdownMenuItem>
        <DropdownMenuItem
          className="cursor-pointer text-destructive"
          onClick={handleDelete}
        >
          <Trash2Icon className="h-4 w-4" />
          Удалить
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ResponsiveResend;
