import { axiosInstance } from "@/services/api/api.ts";
import { Assistant } from "@/schemas/assistant.ts";
import { z } from "zod";
import { statisticsSchema } from "@/schemas/statistics.ts";

class StatisticsAPI {
  private api = axiosInstance("assistants");

  getStatistics = async (assistant_id: Assistant["id"]) => {
    const response = await this.api.get<z.infer<typeof statisticsSchema>>(
      `/${assistant_id}/statistics/`,
    );
    return response.data;
  };
}

export const statisticsAPI = new StatisticsAPI();
