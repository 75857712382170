import { queryOptions } from "@tanstack/react-query";
import { userAPI } from "@/services/api/user.ts";

const DEFAULT_LIMIT = 15;

class UserQueries {
  user = () => {
    return queryOptions({
      queryKey: ["user"],
      queryFn: userAPI.user,
    });
  };

  billingHistory = (page: number, limit: number = DEFAULT_LIMIT) => {
    return queryOptions({
      queryKey: ["user", "billing-history", page],
      queryFn: () => {
        return userAPI.billingHistory({
          limit,
          offset: isNaN(Number(page)) ? undefined : (Number(page) - 1) * limit,
        });
      },
    });
  };
}

export const userQueries = new UserQueries();
