import { z } from "zod";
import {
  paginationParamsSchema,
  paginationRespSchema,
  phoneNumberSchema,
} from "@/schemas/shared.ts";

export const userSchema = z.object({
  id: z.number(),
  phone_number: phoneNumberSchema,
  balance: z.number(),
});

export type User = z.infer<typeof userSchema>;

// billing-history

export const billingHistorySchema = z.object({
  date: z.string().transform((value) => new Date(value)),
  type: z.enum([
    "telegram",
    "whatsapp",
    "playground",
    "wappi_profile",
    "telegram_profile",
  ]),
  operation: z.number(),
  tokens: z.number(),
  assistant_name: z.string(),
});

export const billingHistoryRespSchema = paginationRespSchema.extend({
  current_balance: z.number(),
  total_spent_cost: z.number(),
  total_spent_tokens: z.number(),
  billing_history: z.array(billingHistorySchema),
});

export const billingHistoryParamsSchema = paginationParamsSchema;
