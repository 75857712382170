import { createFileRoute, RoutePaths } from "@tanstack/react-router";
import { z } from "zod";
import { routeTree } from "@/routeTree.gen.ts";

const searchParamsSchema = z.object({
  redirect: z.custom<RoutePaths<typeof routeTree>>().optional(),
});

export const Route = createFileRoute("/_auth/login/")({
  validateSearch: (search) => searchParamsSchema.parse(search),
});
