import { useDashboardContext } from "@/features/dashboard/dashboard.provider.tsx";
import React from "react";

const useHideSidebar = () => {
  const setSidebarVisibility = useDashboardContext().setSidebarVisible;
  React.useEffect(() => {
    setSidebarVisibility(false);

    return () => {
      setSidebarVisibility(true);
    };
  }, []);
};

export default useHideSidebar;
