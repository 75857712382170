import { axiosInstance } from "@/services/api/api.ts";
import {
  billingHistoryParamsSchema,
  billingHistoryRespSchema,
  User,
} from "@/schemas/user.ts";
import { z } from "zod";

class UserAPI {
  private api = axiosInstance("auth/user");

  user = async () => {
    const response = await this.api.get<User | null>("/");
    return response.data;
  };

  billingHistory = async (
    params: z.infer<typeof billingHistoryParamsSchema>,
  ) => {
    const response = await this.api.get<
      z.infer<typeof billingHistoryRespSchema>
    >(`/billing-history/`, {
      params,
    });
    return response.data;
  };
}

export const userAPI = new UserAPI();
